import * as NoUiSlider from "nouislider"
import * as AnalyzerSummaryTemplate from "templates/analyzer-summary.njk";
import * as AnalyzerImageTemplate from "templates/analyzer-image.njk";
import * as AnalyzerErrorTemplate from "templates/analyzer-error.njk";

import {App} from "./app";
import {Utility} from "../utility";
import {Analytics} from "../analytics";

interface ProcessingStats {
    total_count: number;
    orig_size: number;
    optimized_size: number;
    optimized_images: number;
    processing_count: number;
    processed_count: number;
}

export class AnalyzerApp extends App {
    protected max_processing_count: number;
    protected debug: boolean;
    protected def_params: object;
    protected sliders: { [key: string]: NoUiSlider.Instance } = {};
    protected cached_images: any[];
    protected $progress_text: JQuery;
    protected $progress_bar: JQuery;

    constructor(max_processing_count: number = 4, def_params: object = {}, debug: boolean = false) {
        super();

        this.max_processing_count = max_processing_count;
        this.def_params = def_params;
        this.debug = debug;
    }

    run() {
        super.run();

        this.$submit_btn = $("#btn-analyze");

        $('[data-toggle="tooltip"]').tooltip();

        this.initSliders();

        const $txt_url = $("#txt-url");
        const $txt_img_domains = $("#txt-img-domains");

        this.$progress_text = $("#image-analysis-progress-text");
        this.$progress_bar = $("#image-analysis-progressbar");

        $txt_url.on("input", () => {
            this.onUrlChanged($("#txt-url").val().trim(), false);
        });

        $txt_url.on("change", () => {
            this.onUrlChanged($("#txt-url").val().trim(), true);
        });

        if (!$txt_img_domains.val().trim()) {
            $txt_url.trigger('change');
        }

        $("#txt-screen-width").val($(window).width()).on("input", () => {
            this.cached_images = undefined;
        });

        $("#txt-screen-height").val($(window).height()).on("input", () => {
            this.cached_images = undefined;
        });

        $("#txt-user-agent").on("input", () => {
            this.cached_images = undefined;
        });

        $txt_img_domains.on("input", () => {
            this.cached_images = undefined;
        });

        $("#frm-request-page").on("submit", event => {
            event.preventDefault();
            this.handleForm();
        });

        $("#more_settings_toggle").click(() => {
            $('div.more-settings').toggle('fast');
            return false;
        });

        this.hidePreloader();
    }

    protected initSliders() {
        const slider_types = ['quality', 'jpeg_quality', 'png_quality', 'gif_quality'];

        slider_types.forEach(type => {
            this.sliders[type] = this.initSlider(type);
        });
    }

    protected initSlider(slider_type: string): NoUiSlider.Instance {
        const slider = $(`#${slider_type}_slider`).get(0) as NoUiSlider.Instance;
        NoUiSlider.create(slider, {
            start: [+$(`#inp-${slider_type}`).val()],
            connect: "lower",
            step: 1,
            range: {
                min: [1],
                max: [100]
            }
        });

        slider.noUiSlider.on("update", () => {
            const val = parseInt(slider.noUiSlider.get().toString());
            const lossless = val === 100;

            $(`#inp-${slider_type}`).val(val.toString());

            let val_string = lossless ? "lossless" : `${val}%`;

            $(slider).parent().find('span.js-nouislider-value').text(val_string);

            if (slider_type === "quality") {
                Object.keys(this.sliders).forEach(key => {
                    if (key === "quality") {
                        return;
                    }

                    this.sliders[key].noUiSlider.set(val);
                });
            }
        });

        return slider;
    }

    protected onUrlChanged(url: string, log_analytics: boolean) {
        this.cached_images = undefined;

        if (!url) {
            this.disableSubmitButton();
            return;
        }

        if (log_analytics) {
            Analytics.event("analyzer", "url_changed", url);
        }

        if (/^https?:\/\/[\w\-]+(\.[\w\-]+)*/.test(url)) {
            const tmp = document.createElement('a');
            tmp.href = url;
            const host_parts = tmp.hostname.split('.');
            let img_domain = host_parts.pop();
            if (host_parts.length) {
                img_domain = host_parts.pop() + "." + img_domain;
            }
            $("#txt-img-domains").val(img_domain);
        } else {
            $("#txt-img-domains").val("");
        }

        this.enableSubmitButton();
    }

    protected async handleForm() {
        const form_data = new FormData($('#frm-request-page').get(0) as HTMLFormElement);

        Analytics.event("analyzer", "analyze_submit", {
            url: form_data.get('url'),
            quality: +form_data.get('quality'),
            method: form_data.get('method'),
            domains: form_data.get('img_domains'),
            strip_headers: !!form_data.get('strip'),
            jpeg_quality: +form_data.get('jpeg_quality'),
            png_quality: +form_data.get('png_quality'),
            gif_quality: +form_data.get('gif_quality'),
            width: +form_data.get('width'),
            height: +form_data.get('height'),
            user_agent: form_data.get('user_agent'),
            clear_cache: !!form_data.get('clear_cache')
        });

        this.hideError();
        this.hideContact();

        $("#result-container").hide();
        $("#results-summary").hide();

        $("#preloader-page").show();
        $("#preloader-images").hide();

        if ($("#chk_clear_cache_results").prop("checked")) {
            this.cached_images = undefined;
        }

        await this.showPreloader();

        const url = $("#txt-url").val().trim();
        if (this.debug) {
            console.log(`Analyzing page ${url}`);
        }

        if (this.cached_images) {
            if (this.debug) {
                console.log(`${this.cached_images.length} images found in cache`);
                console.debug(this.cached_images);
            }

            this.processImages(this.cached_images);
            return;
        }

        this.post("analyze-page.php", form_data, response => {
            Analytics.event("analyzer", "crawl_success", url, response['images'].length);

            const query = [
                `url=${encodeURIComponent($("#txt-url").val())}`,
                `m=${encodeURIComponent($("#sel-method").val())}`,
                `q=${encodeURIComponent($("#inp-quality").val())}`,
                `domains=${encodeURIComponent($("#txt-img-domains").val())}`
            ];

            history.pushState(null, null, `page-analyzer.php?${query.join("&")}`);

            this.cached_images = response["images"];
            if (this.debug) {
                console.log(`${response["images"].length} images found`);
                console.debug(response["images"]);
            }
            this.processImages(response["images"]);
            this.showContact();
        }, error => {
            this.hidePreloader();
            this.showError(error);

            Analytics.event("analyzer", "crawl_error", error);
        }, error => {
            this.hidePreloader();
            this.showError(`ERROR - ${error}`);

            Analytics.event("analyzer", "crawl_error", error);
            Analytics.exception(error, true);
        });
    }

    protected processImages(images: any[]) {
        const $results_summary = $("#results-summary");
        const $tab_results = $("#tab-results");
        $("#result-container").show();

        const images_count = images.length;
        const stats: ProcessingStats = {
            total_count: images_count,
            orig_size: 0,
            optimized_size: 0,
            optimized_images: 0,
            processed_count: 0,
            processing_count: 0
        };

        if (!images_count) {
            $results_summary.html("No images found, maybe wrong images domain in More settings?").show();
            this.hidePreloader();
            $tab_results.hide();
            return;
        }
        $tab_results.show();

        $("#preloader-page").hide();
        $("#preloader-images").show();

        const $res_body = $("#results-body");

        let remaining_images = images.slice(0);

        this.updateProgress(stats);

        $res_body.html("");
        $("#total-size").html(Utility.formatSize(0));
        $("#total-optimized-size").html(Utility.formatSize(0));
        $("#total-saved").html(`${Utility.formatSize(0)} (0%)`);
        $results_summary.html(
            AnalyzerSummaryTemplate.render({
                optimized_images: 0,
                orig_size: 0,
                optimized_size: 0,
                saved_size: 0,
                saved_percent: 0
            })
        ).show();

        //todo Get rid of setInterval and replace with async queue
        const processing_timer = window.setInterval(() => {
            if (stats.processed_count === stats.total_count) {
                window.clearInterval(processing_timer);
                this.onProcessingComplete(stats);
                return;
            }

            for (let i = stats.processing_count; i < this.max_processing_count && remaining_images.length; i++) {
                this.analyzeImage(remaining_images.shift(), stats);
            }

        }, 100);
    }

    protected onProcessingComplete(stats: ProcessingStats) {
        const saved = stats.orig_size - stats.optimized_size;
        const saved_percent = stats.orig_size > 0
            ? Math.round((saved / stats.orig_size) * 10000) / 100
            : 0;

        Analytics.event("analyzer", "processing_complete", {
            orig_size: stats.orig_size,
            optimized_size: stats.optimized_size,
            saved: saved,
            saved_percent: saved_percent,
            total_images: stats.total_count,
            optimized_images: stats.optimized_images
        }, saved_percent);

        if (this.debug) {
            console.log("Processing complete");
        }

        $("#total-size").html(Utility.formatSize(stats.orig_size));
        $("#total-optimized-size").html(Utility.formatSize(stats.optimized_size));
        $("#total-saved").html(`${Utility.formatSize(saved)} (${saved_percent}%)`);
        $("#results-summary").html(
            AnalyzerSummaryTemplate.render({
                optimized_images: stats.optimized_images,
                orig_size: stats.orig_size,
                optimized_size: stats.optimized_size,
                saved_size: saved,
                saved_percent: saved_percent
            })
        ).show();

        this.hidePreloader().then(() => {
            $("#preloader-page").hide();
            $("#preloader-images").hide();
        });

        window.location.hash = "#result-container";
    }

    private analyzeImage(image: [string, string], stats: ProcessingStats) {
        const [url, mime] = image;

        const $res_body = $("#results-body");

        let quality = +$("#inp-quality").val();

        switch (mime) {
            case 'image/png':
                quality = +$("#inp-png_quality").val();
                console.debug(url, mime, $("#inp-png_quality").val());
                break;

            case 'image/jpeg':
            case 'image/jpg':
                quality = +$("#inp-jpeg_quality").val();
                console.debug(url, mime, $("#inp-jpeg_quality").val());
                break;

            case 'image/gif':
                quality = +$("#inp-gif_quality").val();
                break;
            default:
        }
        if (this.debug) {
            console.log(`Analyzing ${url}`);
        }

        const params = Object.assign(
            {},
            this.def_params,
            {
                'quality': quality,
                'strip': +$("#chk_remove_headers").prop("checked"),
                'method': $("#sel-method").val(),
                'no-orig-content': 1,
                'url': url
            }
        );

        const handleError = (error: string) => {
            if (this.debug) {
                console.log(`${url} failed: ${error}`);
            }

            $res_body.append($(AnalyzerErrorTemplate.render({
                src_data_url: url,
                src_name: Utility.getFilename(url),
                error: error
            })));

            stats.processing_count--;
            stats.processed_count++;
            this.updateProgress(stats);
        };

        this.post("upload.php", params, response => {
                stats.orig_size += response["orig_size"];
                stats.optimized_size += response["optimized_size"];
                stats.optimized_images++;

                const optimized_data_url = `data:${response["type"]};base64,${response["optimized_image"]}`;
                const saved = response["orig_size"] - response["optimized_size"];
                const saved_percent = response["orig_size"] > 0
                    ? Math.round((saved / response["orig_size"]) * 10000) / 100
                    : 0;

                $res_body.append($(AnalyzerImageTemplate.render({
                    src_data_url: url,
                    src_name: response['name'],
                    optimized_data_url: optimized_data_url,
                    optimized_name: response['optimized_name'],
                    width: response['width'],
                    height: response['height'],
                    orig_size: response["orig_size"],
                    optimized_size: response["optimized_size"],
                    saved_size: saved,
                    saved_percent: saved_percent
                })));

                stats.processing_count--;
                stats.processed_count++;
                this.updateProgress(stats);

            },
            handleError,
            handleError
        );
    }

    protected updateProgress(stats: ProcessingStats) {
        const percent_done = Math.round(stats.processed_count / stats.total_count * 10000) / 100;
        const saved = stats.orig_size - stats.optimized_size;
        const saved_percent = stats.orig_size > 0
            ? Math.round((saved / stats.orig_size) * 10000) / 100
            : 0;

        this.$progress_text.html(`${stats.processed_count} / ${stats.total_count} (${percent_done}%), saved ${Utility.formatSize(saved)} (${saved_percent}%) for now`);
        this.$progress_bar.prop("aria-valuenow", percent_done).css("width", `${percent_done}%`);
    }
}
var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], {"dev":false,"autoescape":true,"throwOnUndefined":false,"trimBlocks":false,"lstripBlocks":false});
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/var/www/html/demo/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["src/templates/archive-image.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = null;
var colno = null;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"row\" style=\"border-bottom: 1px solid #BBBBBB;padding-top: 10px;\">\n    <div class=\"col-sm-2 col-xs-6\" style=\"word-wrap: break-word\"><img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src_data_url"), env.opts.autoescape);
output += "\" style=\"max-height: 150px; max-width:100%;\"><br>\n        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src_data_url"), env.opts.autoescape);
output += "\" target=\"_blank\" download=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src_name"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src_name"), env.opts.autoescape);
output += "</a>\n    </div>\n    <div class=\"col-sm-2 col-xs-6\" style=\"word-wrap: break-word\"><img src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "optimized_data_url"), env.opts.autoescape);
output += "\" style=\"max-height: 150px; max-width:100%;\"><br>\n        <a href=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "optimized_data_url"), env.opts.autoescape);
output += "\" target=\"_blank\" download=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "optimized_name"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "optimized_name"), env.opts.autoescape);
output += "</a>\n    </div>\n    <div class=\"col-sm-2 col-xs-3\">\n        <div class=\"visible-xs font-bold\">Resolution:</div>";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "width"), env.opts.autoescape);
output += "x";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "height"), env.opts.autoescape);
output += "</div>\n    <div class=\"col-sm-2 col-xs-3\">\n        <div class=\"visible-xs font-bold\">Original size:</div>";
output += runtime.suppressValue(env.getFilter("filesize").call(context, runtime.contextOrFrameLookup(context, frame, "orig_size")), env.opts.autoescape);
output += "</div>\n    <div class=\"col-sm-2 col-xs-3\">\n        <div class=\"visible-xs font-bold\">Optimized size:</div>";
output += runtime.suppressValue(env.getFilter("filesize").call(context, runtime.contextOrFrameLookup(context, frame, "optimized_size")), env.opts.autoescape);
output += "</div>\n    <div class=\"col-sm-2 col-xs-3\">\n        <div class=\"visible-xs font-bold\">Saved:</div>";
output += runtime.suppressValue(env.getFilter("filesize").call(context, runtime.contextOrFrameLookup(context, frame, "saved_size")), env.opts.autoescape);
output += " (";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "saved_percent"), env.opts.autoescape);
output += "%)\n    </div>\n</div>";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["src/templates/archive-image.njk"] , dependencies)
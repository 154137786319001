import * as Dropzone from "dropzone"
import * as NoUiSlider from "nouislider"
import * as ArchiveImageTemplate from "templates/archive-image.njk";
import * as ArchiveErrorTemplate from "templates/archive-error.njk";

import {App} from "./app";
import {Utility} from "../utility";
import {Analytics} from "../analytics";

export class ArchiveApp extends App {
    protected supported_types: string[];
    protected max_file_size: number;
    protected file_picker: Dropzone;
    protected sliders: {[key: string]: NoUiSlider.Instance} = {};

    constructor(supported_types: string[], max_file_size: number = 4) {
        super();
        this.supported_types = supported_types;
        this.max_file_size = max_file_size;
    }

    run() {
        super.run();

        this.disableSubmitButton();

        this.initDropzone();
        this.initSliders();

        $("#txt-url").on("change", () => {
            this.onFileUrlChanged($("#txt-url").val().trim());
        });

        $("#btn-remove-archive").on("click", () => {
            this.chooseNextFile(true);
        });

        $("#frm-upload-file").on("submit", event => {
            event.preventDefault();
            this.handleForm();
        });

        $("#h2-select").find("a").on("click", event => {
            event.preventDefault();
            this.chooseNextFile();
        });

        $("#btn-select-next-archive").on("click", () => {
            $("#result-container").fadeOut("fast");
            this.chooseNextFile();
        });

        $("#more_settings_toggle").click(() => {
            $('div.more-settings').toggle('fast');
            return false;
        });

        this.hidePreloader();
    }

    protected initDropzone() {
        this.file_picker = new Dropzone("#archive-dropzone", {
            url: "upload.php",
            method: 'post',
            uploadMultiple: false,
            paramName: "archive",
            addRemoveLinks: true,
            createImageThumbnails: true,
            maxFilesize: this.max_file_size,
            maxFiles: 1,
            acceptedFiles: this.supported_types.join(','),
            autoProcessQueue: false,

            success: (file, response) => {
                const filename = response;
                file.previewElement.classList.add("dz-success");
                console.log("Successfully uploaded :" + filename);
            },

            error: file => {
                file.previewElement.classList.add("dz-error");
            }
        });

        this.file_picker.on("addedfile", file => {
            this.onFileAdded(file);
        });

        this.file_picker.on("removedfile", () => {
            this.onFileRemoved();
        });
    }

    protected onFileAdded(file: any) {
        Analytics.event("image", "image_selected");

        $("#selected-orig-archive-url").fadeOut("fast");
        $("#archive-picker").fadeOut("fast", () => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                $("#selected-orig-archive-control").show("fast");
                $("#orig_stats").html(`${file.name}, ${Utility.formatSize(file.size)}`);
                this.enableSubmitButton();

                Analytics.event("archive", "archive_loaded", {
                    name: file.name,
                    size: file.size
                }, file.size);
            }, false);

            reader.readAsDataURL(file);
        });

        if (this.file_picker.files.length > 1) {
            this.file_picker.removeFile(this.file_picker.files[0]);
        }

        this.hideError();
    }

    protected onFileRemoved() {
        Analytics.event("archive", "archive_removed");
        this.disableSubmitButton();
        this.hideError();
    }

    protected initSliders(){
        const slider_types = ['quality', 'jpeg_quality', 'png_quality', 'gif_quality'];

        slider_types.forEach(type => {
            this.sliders[type] = this.initSlider(type);
        });
    }

    protected initSlider(slider_type: string): NoUiSlider.Instance {
        const slider = $(`#${slider_type}_slider`).get(0) as NoUiSlider.Instance;
        NoUiSlider.create(slider, {
            start: [+$(`#inp-${slider_type}`).val()],
            connect: "lower",
            step: 1,
            range: {
                min: [1],
                max: [100]
            }
        });

        slider.noUiSlider.on("update", () => {
            const val = parseInt(slider.noUiSlider.get().toString());
            const lossless = val === 100;

            $("#inp-quality").val(val.toString());

            let val_string = lossless ? "lossless" : `${val}%`;

            $(slider).parent().find('span.js-nouislider-value').text(val_string);

            if(slider_type === "quality"){
                Object.keys(this.sliders).forEach(key => {
                    if(key === "quality"){
                        return;
                    }

                    this.sliders[key].noUiSlider.set(val);
                });
            }
        });

        return slider;
    }

    protected chooseNextFile(reset_selected: boolean = false) {
        $("#h2-select").hide();
        $("#h2-select-readonly").show();
        $("#select-archive-body").show('fast');
        $("#result-container").hide("fast");
        this.hideContact();

        if (reset_selected) {
            $("#selected-orig-archive").hide("fast");
            $("#selected-orig-archive-control").hide("fast");
            $("#archive-picker").show("fast");
            $("#selected-orig-archive-url").show("fast");
            this.file_picker.removeAllFiles();
            $("#txt-url").val("");
            this.disableSubmitButton();
        }

        window.location.hash = '#nav-top';
    }

    protected onFileUrlChanged(url: string) {
        if (!url) {
            return;
        }

        if (!/^https?:\/\/.+/.test(url)) {
            this.showError('Invalid URL format');
            return;
        } else {
            this.hideError();
        }
        $("#selected-orig-archive-url").fadeOut("fast");
        $("#archive-picker").fadeOut("fast");
        $("#selected-orig-archive-control").show("fast");
        $("#orig_stats").text(name);

        this.enableSubmitButton();
        Analytics.event("archive", "url_changed", url);
    }

    protected async handleForm() {
        const form_data = new FormData($('#frm-upload-file').get(0) as HTMLFormElement);

        if (this.file_picker.files.length) {
            form_data.append('archive', this.file_picker.files[0]);
        }

        Analytics.event("archive", "archive_submit", {
            quality: +form_data.get('quality'),
            strip_headers: !!form_data.get('strip'),
            method: form_data.get('method'),
            jpeg_quality: +form_data.get('jpeg_quality'),
            png_quality: +form_data.get('png_quality'),
            gif_quality: +form_data.get('gif_quality')
        });

        this.hideError();
        this.hideContact();
        $("#result-container").hide();
        $("#resized_image").prop("src", "");
        if($("#chk_show_preview").prop("checked")){
            $("#tab-results").show();
        } else {
            $("#tab-results").hide();
        }
        const $res_body = $("#results-body");
        $res_body.html("");
        $("#optimization-result-errors").hide();

        await this.showPreloader();
        this.post("upload-archive.php", form_data, response => {
            this.hidePreloader();

            $("#h2-select-readonly").hide();
            $("#h2-select").show();
            $("#optimized-fn").text(response["optimized_name"]);

            let results = `Original size: <strong>${Utility.formatSize(response['orig_size'])}</strong>, optimized size: <strong>${Utility.formatSize(response['optimized_size'])}</strong>`;
            if(response['optimized_percent'] >= 100){
                if(!response['optimized_images_count']){
                    results += " - no images to optimize"
                } else {
                    results += " - already well optimized"
                }
            } else {
                results += ` - saved <strong>${Utility.formatSize(response['optimized_saved'])}</strong> (<strong>${response["optimized_saved_percent"]}%</strong>)`;
                if(response['optimized_images_count'] > 0){
                    results += `<br>(${response['optimized_images_count']} image(s) optimized`;
                    if(!response['optimized_images_saved']){
                        results += " - already well optimized"
                    } else {
                        results += ` from <strong>${Utility.formatSize(response['optimized_images_orig_size'])}</strong> to <strong>${Utility.formatSize(response['optimized_images_size'])}</strong> `;
                        results += ` - saved <strong>${Utility.formatSize(response['optimized_images_saved'])}</strong> (<strong>${response["optimized_images_saved_percent"]}%</strong>`;
                    }
                    results += ")";
                }
            }

            if(response["errors"].length){
                $("#optimization-result-errors").html(`${response["errors"].length} errors occurred`).show();
            }

            $("#optimization-result-stats").html(results);

            $("#btn-download")
                .prop("download", response["name"])
                .prop("target", "_blank")
                .prop("href", `data:${response["type"]};base64,${response["optimized_archive"]}`);

            $("#select-archive-body").hide("fast");
            $("#result-container").show("fast", () => {
                window.location.hash = "#result-container";
            });

            if(!$("#chk_show_preview").prop("checked")){
                return;
            }

            this.showImages(response['optimized_files']);
            this.showErrors(response['errors']);
            this.showContact();

            Analytics.event("image", "optimize_success", {
                optimized_images_count: response['optimized_images_count'],
                orig_size: response['orig_size'],
                optimized_size: response['optimized_size'],
                optimized_percent: response['optimized_saved_percent'],
                optimized_images_orig_size: response['optimized_images_orig_size'],
                optimized_images_size: response['optimized_images_size'],
                optimized_images_saved_percent: response['optimized_images_saved_percent'],
                duration: response['duration']
            }, response['optimized_saved_percent'])
        }, error => {
            this.hidePreloader();
            this.showError(error);
            this.disableSubmitButton();

            Analytics.event("archive", "optimize_error", error);
        }, error => {
            this.hidePreloader();
            this.showError(`ERROR - ${error}`);

            Analytics.event("archive", "optimize_error", error);
            Analytics.exception(error, true);
        });
    }

    protected showImages(images: object[]) {
        const $res_body = $("#results-body");

        const stats = {
            orig_size: 0,
            optimized_size: 0,
            optimized_images: 0
        };

        images.forEach(function(data){
            stats["orig_size"] += data["orig_size"];
            stats["optimized_size"] += data["optimized_size"];
            stats["optimized_images"]++;

            const src_data_url = `data:${data["type"]};base64,${data["orig_image"]}`;
            const optimized_data_url = `data:${data["type"]};base64,${data["optimized_image"]}`;
            const saved = data["orig_size"] - data["optimized_size"];
            const saved_percent = data["orig_size"] > 0
                ? Math.round((saved / data["orig_size"]) * 10000) / 100
                : 0;

            $res_body.append($(ArchiveImageTemplate.render({
                src_data_url: src_data_url,
                src_name: data['name'],
                optimized_data_url: optimized_data_url,
                optimized_name: data['optimized_name'],
                width: data['width'],
                height: data['height'],
                orig_size: data["orig_size"],
                optimized_size: data["optimized_size"],
                saved_size: saved,
                saved_percent: saved_percent
            })));
        });

        const saved = stats["orig_size"] - stats["optimized_size"];
        const saved_percent = stats["orig_size"] > 0
            ? Math.round((saved / stats["orig_size"]) * 10000) / 100
            : 0;

        const orig_size_formatted = Utility.formatSize(stats["orig_size"]);
        const optimized_size_formatted = Utility.formatSize(stats["optimized_size"]);
        const saved_size_formatted = Utility.formatSize(saved);

        $("#res-total-size").html(orig_size_formatted);
        $("#res-total-optimized-size").html(optimized_size_formatted);
        $("#res-total-saved").html(saved_size_formatted + " (" + saved_percent + "%)");
    }

    protected showErrors(errors: object[]) {
        const $res_body = $("#results-body");

        errors.forEach(function(error){
            const src_data_url = `data:${error["type"]};base64,${error["optimized_image"]}`;

            $res_body.append($(ArchiveErrorTemplate.render({
                src_data_url: src_data_url,
                src_name: error['name'],
                error: error['error']
            })));
        })
    }
}
export class Analytics {
    static event(event_category: string, event_id: string, event_label?: any, event_value?: number){
        const event_data = {
            event_category: event_category,
        };

        if(event_label !== undefined){
            switch(typeof event_label){
                case "string":
                    event_data["event_label"] = event_label;
                    break;

                default:
                    event_data["event_label"] = JSON.stringify(event_label);
            }
        }

        if(event_value !== undefined){
            event_data["value"] = event_value;
        }

        Analytics.action("event", event_id, event_data);
    }

    static exception(description: string, fatal: boolean){
        Analytics.action("event", "exception", {
            description: description,
            fatal: fatal
        })
    }

    static action(...data: any[]){
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push(arguments);
    }
}
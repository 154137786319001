import * as Dropzone from "dropzone";

import {App} from "./app";
import {Utility} from "../utility";
import {Analytics} from "../analytics";

export class BannerApp extends App {
    protected supported_types: string[];
    protected max_file_size: number;
    protected file_picker: Dropzone;

    constructor(supported_types: string[], max_file_size: number = 4) {
        super();
        this.supported_types = supported_types;
        this.max_file_size = max_file_size;
    }

    run() {
        super.run();

        this.disableSubmitButton();

        this.initDropzone();

        $("#txt-url").on("change", () => {
            this.onFileUrlChanged($("#txt-url").val().trim());
        });

        $("#btn-remove-image").on("click", () => {
            this.chooseNextFile(true);
        });

        $("#frm-upload-file").on("submit", event => {
            event.preventDefault();
            this.handleForm();
        });

        $("#h2-select").find("a").on("click", event => {
            event.preventDefault();
            this.chooseNextFile();
        });

        $("#btn-select-next-image").on("click", () => {
            $("#result-container").fadeOut("fast");
            this.chooseNextFile();
        });

        $("#txt-max-size").on("input", () => {
            this.onMaxSizeChanged();
        });

        $("#sel-max-size-units").on("input", () => {
            this.onMaxSizeChanged();
        });

        this.onMaxSizeChanged();
        this.hidePreloader();
    }

    protected initDropzone() {
        this.file_picker = new Dropzone("#image-dropzone", {
            url: "upload.php",
            method: 'post',
            uploadMultiple: false,
            paramName: "image",
            addRemoveLinks: true,
            createImageThumbnails: true,
            maxFilesize: this.max_file_size,
            maxFiles: 1,
            acceptedFiles: this.supported_types.join(','),
            autoProcessQueue: false,

            success: (file, response) => {
                const filename = response;
                file.previewElement.classList.add("dz-success");
                console.log("Successfully uploaded :" + filename);
            },

            error: file => {
                file.previewElement.classList.add("dz-error");
            }
        });

        this.file_picker.on("addedfile", file => {
            this.onFileAdded(file);
        });

        this.file_picker.on("removedfile", () => {
            this.onFileRemoved();
        });
    }

    protected onFileAdded(file: any) {
        Analytics.event("banner", "banner_selected");

        if (file["type"] === 'image/jpeg') {
            $("#sel-quality-container").show('fast');
            $("#sel-remove-headers-container").show('fast');
        } else {
            $("#sel-quality-container").hide('fast');
            $("#sel-remove-headers-container").hide('fast');
        }

        $("#selected-orig-image-url").fadeOut("fast");
        $("#image-picker").fadeOut("fast", () => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                $("#original_image").prop("src", reader.result);
                $("#selected-orig-image").show("fast");
                $("#selected-orig-image-control").show("fast");
                let dimensions = '';

                //todo width and height properties should not exist on DropzoneFile by specification
                if (file['width']) {
                    dimensions = `, ${file['width']}x${file['height']}`;
                }
                $("#orig_stats").html(`${file.name}${dimensions}, ${Utility.formatSize(file.size)}`);
                this.enableSubmitButton();

                this.onMaxSizeChanged();
                this.detectMaximalSize(file.name);

                Analytics.event("banner", "banner_loaded", {
                    name: file.name,
                    width: file.width,
                    height: file.height,
                    size: file.size
                }, file.size);
            }, false);

            reader.readAsDataURL(file);
        });

        if (this.file_picker.files.length > 1) {
            this.file_picker.removeFile(this.file_picker.files[0]);
        }

        this.hideError();
    }

    protected onFileRemoved() {
        Analytics.event("banner", "banner_removed");
        this.disableSubmitButton();
        this.hideError();
        $("#sel-quality-container").hide('fast');
        $("#sel-remove-headers-container").hide('fast');
    }

    protected chooseNextFile(reset_selected: boolean = false) {
        $("#h2-select").hide();
        $("#h2-select-readonly").show();
        $("#select-image-body").show('fast');
        this.hideContact();

        if (reset_selected) {
            $("#selected-orig-image").hide("fast");
            $("#selected-orig-image-control").hide("fast");
            $("#image-picker").show("fast");
            $("#selected-orig-image-url").show("fast");
            this.file_picker.removeAllFiles();
            $("#txt-url").val("");
            this.disableSubmitButton();
        }

        window.location.hash = '#nav-top';
    }

    protected onFileUrlChanged(url: string) {
        if (!url) {
            return;
        }

        if (!/^https?:\/\/.+/.test(url) && !/^data:image\/\w+;base64,.+/.test(url)) {
            this.showError('Invalid URL format');
            return;
        }

        const image = new Image();
        image.onload = () => {
            let name: string;
            if (/^data:/.test(url)) {
                const mime = url.substr(5).split(';')[0];
                switch (mime) {
                    case 'image/jpeg':
                        name = "image.jpg";
                        break;
                    case 'image/png':
                        name = 'image.png';
                        break;
                    case 'image/gif':
                        name = 'image.gif';
                        break;

                    default:
                        name = 'image';
                }

            } else {
                const fragments = url.split('?')[0].split('#')[0].split('/');
                name = fragments.pop();
                if (!name) {
                    name = fragments.pop();
                }
            }

            $("#selected-orig-image-url").fadeOut("fast");
            $("#image-picker").fadeOut("fast");
            $("#original_image").prop("src", url);
            $("#selected-orig-image").show("fast");
            $("#selected-orig-image-control").show("fast");
            $("#orig_stats").html(`${name}, ${image.naturalWidth}x${image.naturalHeight}`);
            this.enableSubmitButton();
            this.onMaxSizeChanged();
            this.detectMaximalSize(name);
        };

        image.onerror = () => {
            this.showError("Failed to load image from URL");
        };
        image.src = url;

        if (/^data:/.test(url)) {
            const mime = url.substr(5).split(';')[0];

            Analytics.event("banner", "url_changed", `data:${mime}`);
        } else {
            Analytics.event("banner", "url_changed", `data:${url}`);
        }
    }

    protected async handleForm() {
        const form_data = new FormData($('#frm-upload-file').get(0) as HTMLFormElement);

        if (this.file_picker.files.length) {
            form_data.append('image', this.file_picker.files[0]);
        }

        const target_size = +$("#inp-max-size").val();

        Analytics.event("banner", "optimize_submit", {
            target_size: target_size
        }, target_size);

        this.hideError();
        this.hideContact();
        $("#result-container").hide();
        $("#resized_image").prop("src", "");

        await this.showPreloader();
        this.post("optimize-banner.php", form_data, response => {
            this.hidePreloader();

            $("#h2-select-readonly").hide();
            $("#h2-select").show();

            let results = "Original size: <strong>" + Utility.formatSize(response['orig_size']) + "</strong>, optimized size: <strong>" + Utility.formatSize(response['optimized_size']) + "</strong>";
            if (response['optimized_size'] > target_size) {
                results += " - failed to achieve " + Utility.formatSize(target_size);
            }

            const $result_stats = $("#optimization-result-stats");
            $result_stats.html(results);
            if (response['optimized_size'] > target_size) {
                $result_stats.removeClass("alert-success");
                $result_stats.addClass("alert-danger");
            } else {
                $result_stats.removeClass("alert-danger");
                $result_stats.addClass("alert-success");
            }

            const src_data_url = `data:${response["type"]};base64,${response["orig_image"]}`;
            const optimized_data_url = `data:${response["type"]};base64,${response["optimized_image"]}`;

            $("#resized_image").prop("src", src_data_url);
            $("#original_image_res").prop("src", optimized_data_url);

            $("#original_image_name")
                .prop("download", response['name'])
                .prop('href', response["url"] || src_data_url)
                .text(response['name']);
            $("#optimized_image_name")
                .prop("download", response['optimized_name'])
                .prop('href', optimized_data_url)
                .text(response['optimized_name']);

            $("#span-orig-size").html(`${response['width']}x${response['height']}, ${Utility.formatSize(response['orig_size'])}`);
            $("#span-optimized-size").html(`${response['width']}x${response['height']}, ${Utility.formatSize(response['optimized_size'])}`);

            $("#select-image-body").hide("fast");
            $("#result-container").show("fast", () => {
                window.location.hash = "#result-container";
            });

            this.showContact();

            Analytics.event("banner", "optimize_success", {
                width: response['width'],
                height: response['height'],
                orig_size: response['orig_size'],
                optimized_size: response['optimized_size'],
                optimized_percent: response['optimized_percent'],
                duration: response['duration']
            }, response['optimized_percent'])
        }, error => {
            this.hidePreloader();
            this.showError(error);
            this.disableSubmitButton();

            Analytics.event("banner", "optimize_error", error);
        }, error => {
            this.hidePreloader();
            this.showError(`ERROR - ${error}`);

            Analytics.event("banner", "optimize_error", error);
            Analytics.exception(error, true);
        });
    }

    protected onMaxSizeChanged() {
        this.hideError();
        const $calculated_size = $("#txt-calculated-size");
        const $txt_max_size = $("#txt-max-size");

        let value = $txt_max_size.val();
        const units = $("#sel-max-size-units").val();

        if (value <= 0) {
            this.showError("Invalid target size");
            this.disableSubmitButton();
            return;
        }

        if (units === 'B') {
            value = Math.ceil(value);
            $txt_max_size.val(value);
        }

        let bytes: number;
        let calc_size_html = "&nbsp;(";

        switch (units) {
            case 'B':
                bytes = Math.ceil(value);
                calc_size_html += `= ${Utility.round(bytes / 1024, 3)} KB, `;
                calc_size_html += `= ${Utility.round(bytes / 1024 / 1024, 3)} MB`;
                break;

            case 'KB':
                bytes = Math.ceil(value * 1024);
                calc_size_html += `= ${bytes} B, `;
                calc_size_html += `= ${Utility.round(bytes / 1024 / 1024, 3)} MB`;
                break;

            case 'MB':
                bytes = Math.ceil(value * 1024 * 1024);
                calc_size_html += `= ${bytes} B, `;
                calc_size_html += `= ${Utility.round(bytes / 1024, 3)} KB`;
                break;

            default:
                bytes = 0;
        }

        calc_size_html += ")";
        $calculated_size.html(calc_size_html);

        $("#inp-max-size").val(bytes);
    }

    protected detectMaximalSize(filename: string) {
        const match = filename.match(/(--|__)([\d]+)([km]?b)\.\w+$/i);

        if (!match) {
            return;
        }

        const value = +match[2].trim();
        const unit = match[3].trim().toUpperCase();

        $("#txt-max-size").val(value);
        $("#sel-max-size-units").val(unit);

        this.onMaxSizeChanged();
    }
}
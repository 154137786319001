interface ViewTemplate {
    // noinspection JSUnusedLocalSymbols
    render(context: object): string;
}

export class Utility {
    static formatSize(size: number, quote_entites: boolean = true): string
    {
        let units = 'B';
        if(size > 1024){
            units = 'kB';
            size /= 1024;
        }

        if(size > 1024){
            units = 'MB';
            size /= 1024;
        }

        return Math.round(size * 100) / 100 + (quote_entites ? "&nbsp;" : "\xa0") + units;
    }

    static htmlspecialchars (val: string): string {
        // Adopted from http://github.com/janl/mustache.js
        const entity_map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            '/': '&#x2F;',
            '`': '&#x60;',
            '=': '&#x3D;'
        };

        return String(val).replace(/[&<>"'`=\/]/g, s => {
            return entity_map[s];
        });
    }

    static round(value: number, places: number): number
    {
        if(places <= 0){
            return Math.round(value);
        }

        return Math.round(value * Math.pow(10, places)) /  Math.pow(10, places);
    }

    static renderView(template: ViewTemplate, context: object = {}): DocumentFragment {
        const temp = document.createElement("template");
        temp.innerHTML = template.render(context);
        return temp.content;
    }

    static getFilename(url: string): string{
        return url.split('/').pop().split('#').shift().split('?').shift();
    }
}
import "jquery"
import "bootstrap"
import {Contact} from "../contact";

export class App {
    protected $error_message: JQuery;
    protected $error_box: JQuery;
    protected $preloader: JQuery;
    protected $contact_form: JQuery;
    protected $submit_btn: JQuery;

    protected contact: Contact = new Contact(this);

    run() {
        this.contact.init();

        this.$error_message = $("#error-message");
        this.$error_box = $("#error-box");
        this.$preloader = $("#div-preloader");
        this.$contact_form = $("#contact-form-container");
        this.$submit_btn = $("#btn-optimize");
    }

    setPreloaderMessage(message: string) {
        $("#preloader-text").html(message);
    }

    protected showError(message: string) {
        this.$error_message.html(message);
        this.$error_box.show('fast');
    }

    protected hideError() {
        this.$error_message.html('');
        this.$error_box.hide('fast');
    }

    async showPreloader() {
        return new Promise(resolve => {
            this.$preloader.fadeTo('fast', 0.8, () => {
                resolve();
            });
        });
    }

    async hidePreloader() {
        return new Promise(resolve => {
            this.$preloader.fadeTo('fast', 0.0, () => {
                this.$preloader.hide();
                resolve();
            });
        })
    }

    protected showContact() {
        this.$contact_form.fadeIn('fast');
    }

    protected hideContact() {
        this.$contact_form.fadeOut('fast');
    }

    protected enableSubmitButton() {
        this.$submit_btn.prop("disabled", false);
    }

    protected disableSubmitButton() {
        this.$submit_btn.prop("disabled", true);
    }

    post(url: string, data: any, on_success: (response: object) => void, on_soft_error: (error: string) => void, on_hard_error: (error: string) => void) {
        const settings = {};
        if(data instanceof FormData){
            settings['processData'] = false;
            settings['contentType'] = false;
        }

        $.ajax(Object.assign({}, settings, {
            type: "POST",
            url: url,
            data: data,
            cache: false,
            timeout: 125000,
            success: response => {
                if (response["status"] === "OK") {
                    on_success(response);
                } else {
                    on_soft_error(response['message']);
                }
            },
            error: (response, error_code, http_error) => {
                let error = http_error;
                if(!error){
                    switch (error_code){
                        case "parserror":
                            error = "Failed to parse response as JSON";
                            break;

                        case "timeout":
                            error = "Request timed out";
                            break;

                        case "abort":
                            error = "Request aborted";
                            break;
                    }
                }

                if(!error){
                    if(!response.status){
                        error = "Not connected to network"
                    } else {
                        error = `Unexpected response code ${response.status}`;
                    }
                }

                if(!error){
                    error = "Unknown error";
                }

                on_hard_error(error);
                console.error(error);
            }
        }));
    }
}
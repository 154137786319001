import {App} from "./app/app";
import {Analytics} from "./analytics";

export class Contact {
    protected app: App;
    protected $form: JQuery;
    protected $submit_btn: JQuery;

    constructor(app: App) {
        this.app = app;
    }

    init(){
        this.$form = $("#frm-contact");
        this.$submit_btn = $("#btn-send-contact");

        $("#inp-contact-name").on("change", () => this.onFormChanged());
        $("#inp-contact-email").on("change", () => this.onFormChanged());
        $("#inp-contact-content").on("change", () => this.onFormChanged());
        $("#contact-toggle").click(() => {
            $('#contact-form-container').toggle('fast');
            return false;
        });
        this.$form.on("submit", event => {
            event.preventDefault();
            this.handleForm();
        });

        this.disableSubmitButton();
    }

    protected onFormChanged(){
        this.hideError();

        if(!$("#inp-contact-name").val().trim()){
            this.disableSubmitButton();
            return;
        }

        if(!$("#inp-contact-content").val().trim()){
            this.disableSubmitButton();
            return;
        }

        const email = $("#inp-contact-email").val().trim();
        if(!/.+@.+\.\w+$/.test(email)){
            this.disableSubmitButton();
            return;
        }

        this.enableSubmitButton();
    }

    protected async handleForm(){
        Analytics.event("contact", "contact_submit");

        const form_data = new FormData(this.$form.get(0) as HTMLFormElement);
        form_data.append('resolution', $(window).width() + "x" + $(window).height());
        this.hideError();
        await this.app.showPreloader();
        this.app.post("send-contact.php", form_data, response => {
            this.app.hidePreloader();
            Analytics.event("contact", "contact_success");
            $("#contact-success-container").show("fast");
            this.$form.hide('fast');
            this.$form.remove();
        }, error => {
            this.app.hidePreloader();
            this.showError(error);
            this.disableSubmitButton();
            Analytics.event("contact", "contact_error", error);
        }, error => {
            this.app.hidePreloader();
            this.showError(`ERROR - ${error}`);
            Analytics.event("contact", "contact_error", error);
        });
    }

    protected showError(error: string){
        $("#contact-error-message").html(error);
        $("#contact-error-box").show("fast");
    }

    protected hideError(){
        $("#contact-error-message").html('');
        $("#contact-error-box").hide("fast");
    }

    protected enableSubmitButton() {
        this.$submit_btn.prop("disabled", false);
    }

    protected disableSubmitButton() {
        this.$submit_btn.prop("disabled", true);
    }
}
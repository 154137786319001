function formatSize(size) {
    let units = 'B';
    if(size > 1024){
        units = 'kB';
        size /= 1024;
    }

    if(size > 1024){
        units = 'MB';
        size /= 1024;
    }

    return Math.round(size * 100) / 100 + "\xa0" + units;
}
module.exports = function(env) {
    env.addFilter("filesize", formatSize);
};